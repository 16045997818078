import React, { useState } from 'react';
import { Grid, Hidden, Typography } from '@material-ui/core';

import JobDialog from './JobDialog';
import { JobButton, JobContainer, PositionText, SummaryBox } from './styled';

const JobPreview: React.FC<any> = ({
  name,
  summary,
  departments,
  location,
  details,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <JobButton fullWidth onClick={() => setIsOpen(true)}>
        <JobContainer width="100%">
          <Grid container spacing={2}>
            <Grid item sm={4} md={7}>
              <PositionText variant="body1">{name}</PositionText>
              <Hidden mdDown>
                <SummaryBox>
                  <Typography
                    variant="body2"
                    style={{ color: '6c6c6c', lineHeight: '17.11px' }}
                  >
                    {summary}
                  </Typography>
                </SummaryBox>
              </Hidden>
            </Grid>
            <Grid item sm={4} md={3}>
              <Typography variant="body1" style={{ lineHeight: '19.65px' }}>
                {departments.name}
              </Typography>
            </Grid>
            <Grid item sm={4} md={2}>
              <Typography variant="body1" style={{ lineHeight: '19.65px' }}>
                {location.name}
              </Typography>
            </Grid>
            <Hidden lgUp>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  style={{ color: '6c6c6c', lineHeight: '17.11px' }}
                >
                  {summary}
                </Typography>
              </Grid>
            </Hidden>
          </Grid>
        </JobContainer>
      </JobButton>
      <JobDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        details={details}
      />
    </>
  );
};

export default JobPreview;
