import styled from 'styled-components';
import { Box, Button, Dialog, Typography } from '@material-ui/core';

import theme from '../../styles/themes';

export const JobContainer = styled(Box)`
  border-radius: 16px;
  border: 1px solid #8ec2ff;
  padding: 20px;
`;

export const SummaryBox = styled(Box)`
  ${theme.breakpoints.up('lg')} {
    padding-right: 52px;
  }
`;

export const PositionText = styled(Typography)`
  line-height: 19.65px;
  ${theme.breakpoints.up('lg')} {
    margin-bottom: 20px;
  }
`;

export const JobButton = styled(Button)`
  padding: 0;
  text-align: left;
  margin: 20px 0;
  border-radius: 16px;
`;

export const TakeTestButton = styled(Button)`
  margin-top: 40px;
  background: linear-gradient(
    97.31deg,
    #1a91ff 2.63%,
    #5c00f2 79.1%,
    #9e24fd 108.63%
  );
  box-shadow: 0px 3px 3px rgba(31, 0, 83, 0.4),
    -2px -3px 11px rgba(0, 111, 213, 0.1), 4px 3px 10px rgba(103, 33, 214, 0.3),
    inset 0px 1px 0px rgba(255, 255, 255, 0.7);
  border-radius: 51px;
  padding: 10px 15px;
  color: #ffffff;

  &:hover {
    opacity: 0.8;
  }

  span {
    font-size: 14px;
  }
`;

export const DialogContainer = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 16px;
  }
`;
