import React from 'react';
import { Box } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { IJobDialog } from '.';
import { DialogContainer } from './styled';

const JobDialog: React.FC<IJobDialog> = ({ onClose, open, details }) => {
  return (
    <DialogContainer onClose={onClose} open={open}>
      <Box p="40px">
        <ReactMarkdown rehypePlugins={[rehypeRaw]} children={details} />
      </Box>
    </DialogContainer>
  );
};

export default JobDialog;
