import React from 'react';
import { Box, Typography } from '@material-ui/core';

import BannerImage from '../../assets/images/careers-banner.png';
import { ContentContainer, ResponsiveImage } from '../../styles/styled';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

const Banner = () => {
  return (
    <Box>
      <ResponsiveImage
        style={{
          backgroundImage: `url(${BannerImage})`,
          backgroundColor: '#052cb3',
        }}
      >
        <ContentContainer>
          <Box maxWidth="480px" color="#ffffff">
            <Typography
              variant="h1"
              style={{
                fontWeight: '500',
                marginBottom: '40px',
                lineHeight: '50.23px',
              }}
            >
              <FormattedHTMLMessage id="careersBannerTitle" />
            </Typography>
            <Typography
              variant="body1"
              style={{ lineHeight: '21.09px', letterSpacing: '1px' }}
            >
              <FormattedHTMLMessage id="careersBannerText" />
            </Typography>
          </Box>
        </ContentContainer>
      </ResponsiveImage>
    </Box>
  );
};

export default Banner;
