import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Hidden,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';
import { useStaticQuery, graphql } from 'gatsby';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ContentContainer } from '../../styles/styled';
import theme from '../../styles/themes';
import JobPreview from '../../components/JobPreview';
import { IJob } from '.';

const Content = styled(ContentContainer)`
  ${theme.breakpoints.up('lg')} {
    padding: 60px 0;
  }
`;

const StyledSelected = styled(Select)`
  border-radius: 58px;
  min-width: 237px;
  .MuiSelect-root {
    padding: 10px 28px 10px 18px;
    background-color: white;
    line-height: 22.09px;
    letter-spacing: 0.5px;
    font-size: 18px;
  }
  svg {
    color: #000000;
  }
  fieldset {
    border-color: #000000;
  }
`;

const JobList = () => {
  const [departments, setDepartments] = useState('');
  const [locations, setLocations] = useState('');
  const intl = useIntl(); // intl.locale

  const query = useStaticQuery(graphql`
    query Query {
      allStrapiJobs {
        nodes {
          name
          departments {
            name
          }
          locale
          location {
            name
          }
          summary
          details
          strapiId
        }
      }
      allStrapiDepartments {
        nodes {
          name
        }
      }
      allStrapiLocations {
        nodes {
          name
        }
      }
    }
  `);

  const [jobsLength, setJobsLength] = useState(
    query.allStrapiJobs.nodes.length
  );
  useEffect(() => {
    const tmp = query.allStrapiJobs.nodes.filter(
      (item: IJob) =>
        item.departments.name.includes(departments) &&
        item.location.name.includes(locations)
    ).length;
    setJobsLength(tmp);
    setPageCount(Math.min(7, tmp));
  }, [departments, locations]);
  const [pageCount, setPageCount] = useState(
    Math.min(7, query.allStrapiJobs.nodes.length)
  );

  return (
    <Content>
      <Typography
        variant="h3"
        style={{ lineHeight: '26.21px', marginBottom: '40px' }}
      >
        <FormattedHTMLMessage id="careersJobsTitle" />
      </Typography>
      <Box mb="40px">
        <Grid container spacing={3}>
          <Grid item>
            <StyledSelected
              id="rep"
              variant="outlined"
              onChange={(e: any) => setDepartments(e.target.value)}
              value={departments}
              displayEmpty
            >
              <MenuItem value="">
                <FormattedHTMLMessage id="careersJobsAllDeps" />
              </MenuItem>
              {query.allStrapiDepartments.nodes?.map((dep: any) => (
                <MenuItem key={dep.name} value={dep.name}>
                  {dep.name}
                </MenuItem>
              ))}
            </StyledSelected>
          </Grid>
          <Grid item>
            <StyledSelected
              id="rep"
              variant="outlined"
              onChange={(e: any) => setLocations(e.target.value)}
              value={locations}
              displayEmpty
            >
              <MenuItem value="">
                <FormattedHTMLMessage id="careersJobsAllLoc" />
              </MenuItem>
              {query.allStrapiLocations.nodes?.map((loc: any) => (
                <MenuItem key={loc.name} value={loc.name}>
                  {loc.name}
                </MenuItem>
              ))}
            </StyledSelected>
          </Grid>
        </Grid>
      </Box>
      <Hidden only="xs">
        <Box p="0 20px">
          <Grid container spacing={2}>
            <Grid item sm={4} md={7}>
              <Box display="flex" alignItems="flex-start">
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: '700',
                    lineHeight: '19.65px',
                    marginRight: '10px',
                    marginLeft: '-20px',
                  }}
                >
                  <FormattedHTMLMessage id="careersJobsPos" />
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ color: '#C0C0C0', lineHeight: '15.29px' }}
                >
                  {jobsLength}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={4} md={3}>
              <Typography
                variant="body1"
                style={{
                  fontWeight: '700',
                  lineHeight: '19.65px',
                }}
              >
                <FormattedHTMLMessage id="careersJobsDeps" />
              </Typography>
            </Grid>
            <Grid item sm={4} md={2}>
              <Typography
                variant="body1"
                style={{
                  fontWeight: '700',
                  lineHeight: '19.65px',
                }}
              >
                <FormattedHTMLMessage id="careersJobsLoc" />
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Hidden>
      {query?.allStrapiJobs.nodes
        .filter(
          (item: IJob) =>
            item.departments.name.includes(departments) &&
            item.location.name.includes(locations) &&
            item.locale === intl.locale
        )
        .slice(0, pageCount)
        .map((job: IJob) => (
          <JobPreview
            key={job.name + job.departments.name + job.strapiId}
            {...job}
          />
        ))}
      {pageCount < jobsLength && (
        <Box display="flex" justifyContent="center">
          <Button
            onClick={() => setPageCount(Math.min(pageCount + 7, jobsLength))}
          >
            <FormattedHTMLMessage id="careersJobsLoad" />
            <ExpandMoreIcon />
          </Button>
        </Box>
      )}
    </Content>
  );
};

export default JobList;
