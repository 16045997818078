import React from 'react';

import SEO from '../components/SEO';
import Layout from '../layout/AppLayout';
import { Banner, JobList } from '../screens/Careers';

const Careers = () => {
  return (
    <Layout>
      <SEO title="Careers" />
      <Banner />
      <JobList />
    </Layout>
  );
};

export default Careers;
